@import "variables";
@import "mixins";

.quiz {
  margin-top: 11.57vh;

  @include respond-to(tablet) {
    margin-top: 7.69vh;
  }

  @include respond-to(small_tablet) {
    margin-top: 40px;
  }

  &-page {
    min-height: 100vh;
    padding-bottom: 40px;
    box-sizing: border-box;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/content/quiz/bg_intro.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // transition: all 5.5s ease-in;
    overflow: hidden;

    @include respond-to(small_tablet) {
      padding-bottom: 20px;
    }

    &--content {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/content/quiz/bg_content.jpg);
    }

    &--result {
      background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/content/quiz/bg_result.jpg);
    }

    .container {
      width: 860px;
    }
  }

  &__intro {

    &-desc {
      margin-top: 5.34vh;

      @include respond-to(small_tablet) {
        margin-top: 20px;
      }
    }

    .title {
      transition: none;

      span {
        margin-bottom: 20px;
        font-size: 1.875vw;

        @include respond-to(desktop) {
          font-size: 2.5vw;
        }

        @include respond-to(tablet) {
          font-size: 3.85vh;
        }

        @include respond-to(small_tablet) {
          margin-bottom: 10px;
          font-size: 2.4rem;
        }
      }
    }
  }

  &__content,
  &__result {
    display: none;
  }

  &-title {
    font-family: $oswald;
    font-size: 1.875vw;
    font-weight: normal;
    line-height: 130%;
    text-align: center;

    @include respond-to(desktop) {
      font-size: 2.2vw;
    }

    @include respond-to(tablet) {
      font-size: 3.45vh;
    }

    @include respond-to(small_tablet) {
      font-size: 1.8rem;
    }
  }

  &-img {
    @include borderRadius(13.281vw, 13.281vw);

    @include respond-to(tablet) {
      width: 28.87vh;
      max-width: 270px;
      height: 28.87vh;
      max-height: 270px;
    }

    @include respond-to(small_tablet) {
      width: 17.6vh;
      max-width: 170px;
      height: 17.6vh;
      max-height: 170px;
    }
  }

  .btn {
    margin: 4.34vh auto 0;

    @include respond-to(tablet) {
      margin-top: 5.34vh;
    }

    @include respond-to(small_tablet) {
      margin-top: 20px;
    }

    &-next {

      @include respond-to(small_tablet) {
        margin-top: 55px;
      }
    }

    &-again {

      @include respond-to(tablet) {
        margin-top: 75px;
      }
    }

    &:disabled {
      border-color: rgba($white, 0.5);
      color: rgba($white, 0.5);
      cursor: default;

      &:hover {
        border-color: rgba($white, 0.5);
        color: rgba($white, 0.5);
      }
    }
  }
}

.question {
  display: none;
  margin-top: 10px;

  &-num {
    font-size: 1.875vw;
    font-style: italic;
    line-height: 1;
    text-align: center;

    @include respond-to(desktop) {
      font-size: 2.2vw;
    }

    @include respond-to(tablet) {
      font-size: 3.45vh;
    }

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }
  }
}

.answers {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8.69vh;

  @include respond-to(tablet) {
    margin-top: 5.34vh;
  }

  @include respond-to(small_tablet) {
    flex-direction: column;
    align-items: center;
    justify-content: initial;
    margin-top: 30px;
  }

  &__item {
    color: $white;
    box-sizing: border-box;

    @include respond-to(small_tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }

    &:not(:first-child) {

      @include respond-to(small_tablet) {
        margin-top: 25px;
        padding-top: 25px;
        border-top: 1px solid $white;
      }
    }

    &:hover,
    &.is-active {

      .answers__text span {
        border-color: $orange;
        background-color: $orange;

        @include respond-to(tablet) {
          background-color: transparent;
        }
      }

      .answers__img-wrap::before {
        opacity: 1;

        @include respond-to(tablet) {
          display: none;
        }
      }
    }
  }

  &__img {

    &-wrap {
      position: relative;

      &::before {
        content: '';
        @include positionCenter();
        display: block;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border: 3px solid $orange;
        border-radius: 100%;
        opacity: 0;
        transition: $basicTransition;
      }
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.34vh;
    font-size: 1.8rem;
    line-height: 150%;
    text-transform: uppercase;

    @include respond-to(desktop) {
      font-size: 1.6rem;
    }

    @include respond-to(tablet) {
      margin-top: 5.34vh;
      font-size: 1.8rem;
    }

    @include respond-to(small_tablet) {
      flex-direction: column;
      justify-content: initial;
      align-items: flex-start;
      margin-top: 0;
      margin-left: 30px;
    }

    span {
      display: inline-block;
      margin-right: 20px;
      @include borderRadius(3.125vw, 3.125vw);
      border: 1px solid $white;
      box-sizing: border-box;
      font-family: $oswald;
      font-size: 1.875vw;
      font-weight: normal;
      line-height: 2.917vw;
      text-align: center;
      transition: $basicTransition;
  
      @include respond-to(tablet) {
        width: 60px;
        height: 60px;
        font-size: 3.5rem;
        line-height: 56px;
      }

      @include respond-to(small_tablet) {
        width: 30px;
        height: 30px;
        margin-right: 0;
        margin-bottom: 10px;
        font-size: 1.8rem;
        line-height: 27px;
      }
    }
  }
}

.answer-result {
  display: none;
  margin-top: 10px;
  text-align: center;

  &__content {
    position: relative;
    display: inline-block;
    margin-top: 17.6vh;

    @include respond-to(tablet) {
      margin-top: 5.34vh;
    }

    @include respond-to(small_tablet) {
      margin-top: 50px;
    }
  }

  &__img {

    @include respond-to(small_tablet) {
      width: 35.71vh;
      max-width: 200px;
      height: 35.71vh;
      max-height: 200px;
    }
  }
}

.smiles {
  position: absolute;
  bottom: 0;
  width: 21.719vw;
  height: 12.135vw;
  background-image: url(../img/content/quiz/icon_smiles_correct.svg);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;

  @include respond-to(tablet) {
    width: 44.59vh;
    max-width: 417px;
    height: 24.91vh;
    max-height: 233px;
  }

  @include respond-to(small_tablet) {
    width: 36.78vh;
    max-width: 206px;
    height: 20.53vh;
    max-height: 115px;
  }

  &--wrong {
    background-image: url(../img/content/quiz/icon_smiles_wrong.svg);
  }

  &--left {
    right: calc(100% + 5.208vw);

    @include respond-to(tablet) {
      right: 100%;
    }
  }

  &--right {
    left: calc(100% + 5.208vw);

    @include respond-to(tablet) {
      left: 100%;
    }
  }
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__num {
    font-family: $oswald;
    font-size: 5.208vw;
    font-weight: bold;
    line-height: 150%;

    @include respond-to(tablet) {
      font-size: 10.69vh;
    }

    @include respond-to(small_tablet) {
      font-size: 4.8rem;
    }
  }

  &__bar {
    display: flex;
    margin-top: 5.34vh;

    @include respond-to(small_tablet) {
      margin-top: 16px;
    }

    &-item {
      width: 20px;
      height: 20px;
      background-color: $white;

      @include respond-to(small_tablet) {
        width: 10px;
        height: 10px;
      }

      &:not(:first-child) {
        margin-left: 15px;

        @include respond-to(small_tablet) {
          margin-left: 10px;
        }
      }

      &.is-orange {
        background-color: $orange;
      }
    }
  }

  &__text {
    margin-top: 5.34vh;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;

    @include respond-to(small_tablet) {
      margin-top: 30px;
      font-size: 1.6rem;
    }

    a {
      color: $white;
      border-bottom: 3px solid $orange;
      transition: $basicTransition;

      &:hover {
        border-bottom: none;
        background-color: $orange;
      }
    }
  }
}

.move-up {
  animation: moveUp 2000ms ease-in;
  animation-delay: 900ms;
}

.move-down {
  animation: moveDown 2000ms ease-in;
  animation-delay: 900ms;
}

@keyframes moveDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  } 50% {
    opacity: 1;
  } 
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  } 50% {
    opacity: 1;
  } 
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
