@import "variables";
@import "mixins";

.section {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;

  &--small {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 360px;
    padding: 20px;
    box-sizing: border-box;
    color: $white;

    @include respond-to(laptop) {
      padding-left: 50px;
      padding-right: 50px;
    }

    @include respond-to(small_tablet) {
      height: 240px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &:hover {

      .title {
        color: $orange;
      }
    }
  }

  &--teaser {
    display: flex;
    flex-direction: column;
    // height: 100vh;
    min-height: 100vh;
    padding-bottom: 7.407vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/content/bg_teaser.jpg);

    @include respond-to(tablet) {
      padding-bottom: 7.8125vh;
    }

    @include respond-to(small_tablet) {
      padding-bottom: 40px;
    }

    &-enhydra {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../img/content/enhydra/bg_teaser_enhydra.jpg);

      @include respond-to(small_tablet) {
        min-height: 100vh;
        background-position: 0,35%;
      }
    }

    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../img/content/eumetopias/bg_teaser_eumetopias.jpg);

      @include respond-to(small_tablet) {
        min-height: 100vh;
      }
    }
  }

  &--enhydra {
    text-align: right;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/content/bg_enhydra.jpg);

    .subtitle--read {
      justify-content: flex-end;
    }
  }

  &--eumetopias {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/content/bg_eumetopias.jpg);
  }

  &--enhydra,
  &--eumetopias {

    &:hover {

      .title {
        color: $orange;
      }

      .subtitle {
        display: flex;
      }
    }
  }

  &--quiz,
  &--wwf {
    
    .container {
      flex-direction: column;
    }
  }

  &--quiz {
    background-image: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(../img/content/bg_quiz.jpg);

    @include respond-to(small_tablet) {
      background-position: 0,60%;
    }

    .title {

      span {
        margin-top: 0;
        margin-bottom: -10px;
        font-size: 3.6rem;

        @include respond-to(small_tablet) {
          display: none;
        }
      }
    }
  }

  &--wwf {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/content/bg_wwf.jpg);
  }

  &--natoons {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/content/bg_natoons.jpg);

    @include respond-to(small_tablet) {
      align-items: flex-start;
    }

    .container {
      padding: 0 60px;

      @include respond-to(laptop) {
        flex-direction: column;
        padding: 0 30px;
      }
    }
  }

  &--two {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/enhydra/bg_two.jpg);
    
    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/eumetopias/bg_two.jpg);
    }
  }

  &--three {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/content/enhydra/bg_three.jpg);

    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/eumetopias/bg_three.jpg);
    }
  }

  &--four {
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/enhydra/bg_four.jpg);

    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/eumetopias/bg_four.jpg);
    }
  }

  &--five {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/content/enhydra/bg_five.jpg);

    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/eumetopias/bg_five.jpg);
    }
  }

  &--six {
    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/eumetopias/bg_six.jpg);
    }
  }

  &--seven {
    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/eumetopias/bg_seven.jpg);
    }
  }

  &--facts {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/content/enhydra/bg_facts.jpg);

    &-eumetopias {
      background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(../img/content/eumetopias/bg_facts.jpg);
    }

    .container {
      flex-direction: column;
      justify-content: center;

      @include respond-to(tablet) {
        justify-content: flex-start;
      }
    }

    .title--medium {

      @include respond-to(tablet) {
        font-size: 8.39vh;
      }

      @include respond-to(small_tablet) {
        font-size: 3.6rem;
      }
    }
  }

  &--footer {

    &-enhydra {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0.1%, #000000 19.85%, rgba(0, 0, 0, 0.8) 36.99%, rgba(0, 0, 0, 0.1) 71.81%, rgba(0, 0, 0, 0.2) 99.87%), url(../img/content/enhydra/bg_footer.jpg);
    }
    &-eumetopias {
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0.1%, #000000 19.85%, rgba(0, 0, 0, 0.8) 36.99%, rgba(0, 0, 0, 0.1) 71.81%, rgba(0, 0, 0, 0.2) 99.87%), url(../img/content/eumetopias/bg_footer.jpg);
    }
  }

  &-list {
    display: flex;

    @include respond-to(small_tablet) {
      flex-wrap: wrap;
    }

    .section {
      width: 50%;
      height: 480px;
      padding: 20px 40px;
      box-sizing: border-box;
      color: $white;
      transition: $basicTransition;
      overflow: hidden;

      @include respond-to(laptop) {
        height: 360px;
      }

      @include respond-to(small_tablet) {
        width: 100%;
        height: 308px;
        padding: 10px 20px;
      }
    }
  }

  &-text {

    .inner {
      padding: 0 11.458vw;

      @include respond-to(hd) {
        padding: 0 7.639vw;
      }

      @include respond-to(desktop) {
        padding: 0;
      }
    }
  }

  &__text {

    &:not(:first-child) {
      margin-top: 7.4vh;

      @include respond-to(tablet) {
        margin-top: 5.859375vh;
      }

      @include respond-to(small_tablet) {
        margin-top: 60px;
      }
    }

    &-icon {
      margin: 0 auto;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

      &--book {
        width: 4.948vw;
        height: 4.167vw;
        background-image: url(../img/general/icon_book.svg);

        @include respond-to(tablet) {
          width: 9.27vh;
          height: 7.81vh;
        }

        @include respond-to(small_tablet) {
          width: 60px;
          height: 50px;
        }
      }

      &--wave {
        width: 5.052vw;
        height: 2.656vw;
        background-image: url(../img/general/icon_wave.svg);

        @include respond-to(tablet) {
          width: 9.47vh;
          height: 4.98vh;
        }

        @include respond-to(small_tablet) {
          width: 62px;
          height: 30px;
        }
      }

      &--tree {
        width: 4.583vw;
        height: 4.167vw;
        background-image: url(../img/general/icon_tree.svg);

        @include respond-to(tablet) {
          width: 8.59vh;
          height: 7.8125vh;
        }

        @include respond-to(small_tablet) {
          width: 55px;
          height: 50px;
        }
      }

      &--earth {
        width: 4.167vw;
        height: 4.167vw;
        background-image: url(../img/general/icon_earth.svg);

        @include respond-to(tablet) {
          width: 7.8125vh;
          height: 7.8125vh;
        }

        @include respond-to(small_tablet) {
          width: 50px;
          height: 50px;
        }
      }

      &--home {
        width: 4.271vw;
        height: 4.167vw;
        background-image: url(../img/general/icon_home.svg);

        @include respond-to(tablet) {
          width: 8vh;
          height: 7.8125vh;
        }

        @include respond-to(small_tablet) {
          width: 52px;
          height: 50px;
        }
      }

      &--quote {
        width: 5.417vw;
        height: 4.067vw;
        background-image: url(../img/general/icon_quote.svg);

        @include respond-to(tablet) {
          width: 9.65vh;
          height: 7.4125vh;
        }

        @include respond-to(small_tablet) {
          width: 62px;
          height: 50px;
        }
      }

      &--hands {
        width: 9.427vw;
        height: 4.167vw;
        background-image: url(../img/general/icon_hands.svg);

        @include respond-to(tablet) {
          width: 14.67vh;
          height: 6.7125vh;
        }

        @include respond-to(small_tablet) {
          width: 114px;
          height: 50px;
        }
      }
    }

    .text {
      margin-top: 3.7vh;
      
      @include respond-to(tablet) {
        margin-top: 30px;
      }
    }
  }

  &__content {
    width: 33.333vw;
    max-width: 100%;

    @include respond-to(hd) {
      width: 46.444vw;
    }

    @include respond-to(laptop) {
      width: 100%;
    }

    &-item {

      &:not(:first-child) {
        margin-top: 3.125vw;

        @include respond-to(small_tablet) {
          margin-top: 40px;
        }
      }
    }

    .logo {

      &--wwf {

        @include respond-to(tablet) {
          width: 8.78vh;
          height: 8.78vh;
        }

        @include respond-to(small_tablet) {
          width: 73px;
          height: 73px;
        }
      }

      &--kinder {

        @include respond-to(tablet) {
          width: 18.94vh;
          height: 5.85vh;
        }

        @include respond-to(small_tablet) {
          width: 159px;
          height: 49px;
        }
      }
    }

    .text {
      text-align: left;
    }
  }

  &__video {
    @include positionCenter();
    z-index: 120;
    display: none;
    width: calc(100% + 5px);
    height: calc(100% + 5px);

    &.is-show {
      display: block;
    }

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .container {
    display: flex;
    align-items: center;
  }
}

.teaser {
  flex-direction: column;
  margin-top: 10.037vh;
  box-sizing: border-box;

  @include respond-to(desktop) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @include respond-to(tablet) {
    margin-top: 14.648vh;
  }

  @include respond-to(small_tablet) {
    margin-top: 28px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__desc {
    width: 858px;
    max-width: 100%;
    margin-top: 4.629vh;

    @include respond-to(small_tablet) {
      margin-top: 60px;
    }

    @include respond-to(small_phone) {
      margin-top: 40px;
    }
  }

  &-nav {
    justify-content: flex-end;

    @include respond-to(small_tablet) {
      margin-top: 130px;
    }

    &.container {

      @include respond-to(tablet) {
        align-items: flex-end;
        padding: 0 75px;
      }

      @include respond-to(small_tablet) {
        padding: 0 20px;
      }
    }

    &__content {
      width: 522px;
      max-width: 100%;

      @include respond-to(tablet) {
        width: 41.91vh;
      }

      @include respond-to(small_tablet) {
        width: 270px;
      }
    }
  }
}

.natoons {

  &__item {

    @include respond-to(small_tablet) {
      margin-top: 10px;
    }

    &:not(:first-child) {
      margin-left: 60px;

      @include respond-to(laptop) {
        margin-top: 20px;
        margin-left: 0;
      }

      @include respond-to(small_tablet) {
        /*display: none;*/
      }
    }
  }

  .title {

    &--small {

      @include respond-to(hd) {
        font-size: 3.8rem;
      }

      @include respond-to(desktop) {
        font-size: 3.4rem;
      }

      @include respond-to(laptop) {
        text-align: center;
      }

      @include respond-to(small_tablet) {
        font-size: 1.7rem;
      }
    }
  }
}

.fullpage {

  .section {
    height: 100vh;
    padding-bottom: 5.208vw !important;
    overflow: hidden;

    @include respond-to(tablet) {
      padding-bottom: 8.78vh !important;
    }

    @include respond-to(small_tablet) {
      height: auto;
      padding: 85px 0 !important;
    }

    &:first-child {

      @include respond-to(small_tablet) {
        padding-top: 0 !important;
      }
    }
  }

  .header {

    .btn-menu {
      display: none;
  
      @include respond-to(small_tablet) {
        display: block;
      }
    }

    &--section {

      .btn-menu {
        display: block;
    
        @include respond-to(small_tablet) {
          display: none;
        }
      }
    }
  }

  .container {
    height: calc(100% - 90px); /* 90px height header*/

    @include respond-to(desktop) {
      height: calc(100% - 70px);
    }

    @include respond-to(laptop) {
      height: calc(100% - 90px);
    }

    @include respond-to(small_tablet) {
      height: auto;
    }
  }

  .text {

    @include respond-to(hd) {
      font-size: 1.6rem;
    }

    @include respond-to(tablet) {
      font-size: 1.75vh;
    }

    @include respond-to(small_tablet) {
      font-size: 1.6rem;
    }

    @include respond-to(small_phone) {
      font-size: 1.4rem;
    }
  }
}

.facts {
  display: flex;
  align-items: flex-start;
  margin-top: 3.125vw;

  @include respond-to(tablet) {
    flex-direction: column;
    align-items: center;
    margin-top: 5.85vh;
  }

  @include respond-to(small_tablet) {
    margin-top: 20px;
  }

  &__item {

    &:first-child {

      @include respond-to(small_tablet) {
        display: none;
      }
    }

    &:not(:first-child) {
      margin-top: 40px;
      margin-left: 3.125vw;

      @include respond-to(tablet) {
        margin-top: 40px;
        margin-left: 0;
      }

      @include respond-to(small_tablet) {
        margin-top: 0;
      }
    }

    &-img {
      display: none;
      flex-shrink: 0;
      width: 25.260vw;
      height: 25.260vw;
      border-radius: 100%;
      overflow: hidden;

      @include respond-to(tablet) {
        width: 35.15625vh;
        height: 35.15625vh;
      }

      &.is-show {
        display: block;
      }
    }
  }

  &__tabs {
    display: flex;
    align-items: center;

    @include respond-to(tablet) {
      justify-content: center;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 3.125vw;
      height: 3.125vw;
      border: 1px solid $orange;
      border-radius: 100%;
      box-sizing: border-box;
      font-family: $oswald;
      font-size: 1.875vw;
      line-height: 1;
      color: $white;
      transition: $basicTransition;

      @include respond-to(tablet) {
        width: 5.85vh;
        height: 5.85vh;
        font-size: 3.51vh;
      }

      @include respond-to(small_tablet) {
        width: 49px;
        height: 49px;
        font-size: 2.4rem;
      }

      &:not(:first-child) {
        margin-left: 25px;

        @include respond-to(small_tablet) {
          margin-left: 8px;
        }
      }

      &.is-active {
        border-color: $gray;
        color: $gray;

        &:hover {
          background-color: transparent;
        }
      }

      &:hover {
        background-color: $orange;
      }
    }
  }

  &__desc {
    margin-top: 3.125vw;

    @include respond-to(small_tablet) {
      // min-height: 20vh;
      margin-top: 20px;
    }

    &-item {
      display: none;

      @include respond-to(small_tablet) {
        min-height: 168px;
      }

      &.is-show {
        display: block;
      }
    }
  } 

  .text {
    text-align: left;

    &:not(:first-of-type) {
      margin-top: 0;
    }
  }
}
