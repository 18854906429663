@import "variables";
@import "mixins";

.share {
  position: relative;
  display: flex;
  align-items: center;

  &-quiz {
    margin-top: 40px;

    .social-list {
      display: flex;
      margin-right: 0;

      @include respond-to(small_tablet) {
        position: relative;
        top: auto;
        right: auto;
        transform: initial;
        padding: 10px 42px;
        background-color: transparent;
      }
    }
  }
}

.btn-reset {
  border: none;
  background: none;
  outline: none;
}

.btn {
  display: block;
  min-width: 280px;
  height: 60px;
  border: 1px solid $white;
  border-radius: 30px;
  box-sizing: border-box;
  font-size: 1.8rem;
  line-height: 60px;
  text-transform: uppercase;
  color: $white;
  text-align: center;
  // transition: $basicTransition;

  @include respond-to(hd) {
    min-width: 240px;
    height: 45px;
    font-size: 1.6rem;
    line-height: 45px;
  }

  @include respond-to(tablet) {
    min-width: 280px;
    height: 60px;
    font-size: 1.8rem;
    line-height: 60px;
  }

  @include respond-to(small_phone) {
    width: 100%;
    min-width: initial;
  }

  &:hover {
    border-color: $orange;
    color: $orange;
  }

  &-share {
    width: 33px;
    height: 33px;
    background-image: url(../img/general/icon_share.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transition: $basicTransition;

    @include respond-to(small_tablet) {
      width: 25px;
      height: 25px;
    }

    &.is-active {
      transform: rotate(180deg);
    }

    &.is-hide {
      visibility: hidden;
      opacity: 0;
    }
  }

  &-nav {
    width: 39px;
    height: 33px;
    margin-left: 40px;
    background-image: url(../img/general/icon_dots.svg);
    background-size: contain;
    background-repeat: no-repeat;
    transition: $basicTransition;

    @include respond-to(laptop) {
      margin-left: 20px;
    }

    @include respond-to(small_tablet) {
      width: 32px;
      height: 26px;
    }

    &.is-hide {
      visibility: hidden;
      opacity: 0;
    }
  }

  &-menu {
    position: relative;
    z-index: 110;
    display: inline-block;
    width: 50px;
    height: 30px;
    margin-left: 40px;

    @include respond-to(laptop) {
      margin-left: 20px;
    }

    @include respond-to(small_tablet) {
      width: 40px;
      height: 26px;
    }
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      transform-origin: left center;
      width: 100%;
      height: 3px;
      background-color: $white;
      transition: $basicTransition;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    &.is-active {
      position: fixed;
      top: 30px;
      right: 40px;
      margin-left: 0;

      @include respond-to(desktop) {
        top: 20px;
      }

      @include respond-to(laptop) {
        top: 30px;
        right: 20px;
      }

      @include respond-to(small_tablet) {
        top: 22px;
      }

      &::before {
        top: -3px;
        left: 8px;
        transform: rotate(45deg);

        @include respond-to(small_tablet) {
          left: 6px;
        }
      }

      &::after {
        bottom: -5px;
        left: 8px;
        transform: rotate(-45deg);

        @include respond-to(small_tablet) {
          bottom: -2px;
          left: 6px;
        }
      }

      span {
       display: none;
      }
    }

    span {
      display: block;
      width: 100%;
      height: 3px;
      background-color: $white;
      @include textHide();
      transition: $basicTransition;
    }
  }

  &-play {
    width: 7.813vw;
    height: 7.813vw;
    border-radius: 100%;
    background-image: url(../img/general/icon_play.svg);
    background-position: left 2.604vw center;
    background-size: 41%;
    background-repeat: no-repeat;
    background-color: $orange;
    animation: pulse 1.5s infinite;

    &:hover {
      animation: none;
    }

    @include respond-to(tablet) {
      width: 14.64vh;
      height: 14.64vh;
      background-position: left 4.88vh center;
    }

    @include respond-to(small_tablet) {
      width: 85px;
      height: 85px;
      background-position: left 30px center;
      background-size: 41%;
    }

    @include respond-to(small_phone) {
      width: 45px;
      height: 45px;
      background-position: 15px;
    }
  }
}

.menu {
  position: fixed;
  top: 0;
  right: -500px;
  z-index: 100;
  width: 333px;
  max-width: 100%;
  height: 100vh;
  padding: 155px 36px 0;
  box-sizing: border-box;
  background-color: $black;
  transition: $basicTransition;

  @include respond-to(laptop) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    padding-top: 100px;
  }

  @include respond-to(small_tablet) {
    padding-top: 100px;
  }

  @include respond-to(phone) {
    width: 100%;
  }

  &.is-show {
    right: 0;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      height: calc(100vh - 120px);
      overflow: auto;
    }

    &-item {
      font-family: $oswald;
      font-size: 2.4rem;
      line-height: 130%;
      color: $white;
      text-transform: uppercase;
      transition: $basicTransition;

      &:not(:first-child) {
        margin-top: 20px;
      }

      &:hover {
        color: $orange;
      }

      &.is-current {
        color: $gray;
      }

      &.is-disable {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}

.block-helper {
  display: none;
  width: 50px;
  margin-left: 40px;

  @include respond-to(laptop) {
    margin-left: 20px;
  }

  @include respond-to(small_tablet) {
    width: 40px;
  }

  &.is-show {
    display: block;
  }
}

.nav {

  &-list {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 10px;
    padding-top: 20px;
    border-top: 3px solid $orange;

    @include respond-to(small_tablet) {
      margin-top: 5px;
      padding-top: 10px;
    }

    &__item {
      font-family: $oswald;
      font-size: 1.875vw;
      line-height: 130%;
      color: $white;
      text-transform: uppercase;
      transition: $basicTransition;

      @include respond-to(tablet) {
        font-size: 3.51vh;
      }

      @include respond-to(small_tablet) {
        font-size: 2.4rem;
      }

      &:not(:first-child) {
        margin-top: 5px;

        @include respond-to(tablet) {
          margin-top: 10px;
        }

        @include respond-to(small_tablet) {
          margin-top: 5px;
        }
      }

      &:hover {
        color: $orange;
      }

      &.is-disable {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }

}

.social-list {
  display: none;
  align-items: center;
  margin-right: 30px;
  padding: 10px 42px;
  box-sizing: border-box;
  border: 1px solid $orange;
  border-radius: 30px;

  @include respond-to(small_tablet) {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 20px;
    padding: 5px 30px;
    background-color: #203f60;
  }

  @include respond-to(phone) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include respond-to(413px) {
    right: calc(100% - 40px);
    z-index: 10;
    margin-right: 0;
  }

  &.is-show {
    display: flex;
  }

  &__item {
    cursor: pointer;
    

    &:not(:first-child) {
      margin-left: 25px;

      @include respond-to(small_tablet) {
        margin-left: 20px;
      }

      @include respond-to(small_phone) {
        margin-left: 15px;
      }
    }

    &:hover {

      path {
        fill: $orange;
        transition: $basicTransition;
      }
    }
  }
}

.video-preview {
  width: 100%;

  &__frame {
    position: relative;
    width: 56.250vw;
    max-width: 100%;
    // height: 694px;
    height: 0;
    margin: 0 auto;
    padding-top: 46.75%;
    background-image: url(../img/general/img_preview_frame.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    @include respond-to(laptop) {
      padding-top: 35.75%;
    }
  
    @include respond-to(tablet) {
      width: 100%;
      // height: 67.77vh;
      padding-top: 56.25%;
      background-image: url(../img/general/img_preview_frame_mobile.png);
    }
  }

  &__top {

    &-left,
    &-right {
      position: absolute;
      top: 0;
      padding: 2.083vw;

      @include respond-to(tablet) {
        padding: 3.9vh;
      }

      @include respond-to(small_tablet) {
        padding: 16px;
      }
    }

    &-left {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }

  &__center {
    position: absolute;
    top: calc(50% - 3.125vw);
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21.875vw;
    max-width: 100%;

    @include respond-to(tablet) {
      top: 50%;
      width: 50vh;
    }

    @include respond-to(small_tablet) {
      width: auto;
    }

    .subtitle {
      margin-top: 0;

      @include respond-to(tablet) {
        display: none;
      }
    }

    .btn-play {
      margin-top: 22px;

      @include respond-to(tablet) {
        margin-top: 0;
      }
    }
  }

  &__bottom {

    &-left,
    &-right {
      position: absolute;
      bottom: 0;
      padding: 2.083vw;

      @include respond-to(tablet) {
        padding: 3.9vh;
      }

      @include respond-to(small_tablet) {
        padding: 16px;
      }
    }

    &-left {
      left: 0;
    }

    &-right {
      right: 0;
    }
  }

  &__rec {
    display: flex;
    align-items: center;
    font-size: 1.875vw;
    line-height: 130%;
    color: $orange;
    text-transform: uppercase;

    @include respond-to(tablet) {
      font-size: 3.6rem;
    }

    @include respond-to(small_tablet) {
      font-size: 1.8rem;
    }

    &::before {
      content: '';
      display: block;
      width: 3.125vw;
      height: 3.125vw;
      margin-right: 1.563vw;
      border-radius: 100%;
      background-color: $orange;

      @include respond-to(tablet) {
        width: 5.85vh;
        height: 5.85vh;
        margin-right: 30px;
      }

      @include respond-to(small_tablet) {
        width: 25px;
        height: 25px;
        margin-right: 14px;
      }
    }
  }

  &__battery {
    width: 4.167vw;
    height: 2.865vw;
    background-image: url(../img/general/icon_battery.svg);
    background-size: cover;
    background-repeat: no-repeat;

    @include respond-to(tablet) {
      width: 7.8125vh;
      height: 5.37vh;
    }

    @include respond-to(small_tablet) {
      width: 34px;
      height: 23px;
    }
  }

  &__title {
    display: none;
    margin-top: 30px;

    @include respond-to(tablet) {
      display: block;
    }
  }

  .text {

    @include respond-to(small_tablet) {
      font-size: 1.2rem !important;
    }
  }
}

.slider {
  position: relative;
  margin-top: 40px;

  &__caption {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    font-size: 1.6rem;
    line-height: 150%;
    font-style: italic;
    text-align: center;
  }

  &__item {
    position: relative;
    height: 0;
    padding-top: 56.25%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .slick {

    &-arrow {
      border: none;
      background: none;
      font-size: 0;
      position: absolute;
      top: calc(50% - 32px);
      transform: translateY(-50%);
      z-index: 99;
      width: 12px;
      height: 24px;
      background-image: url(../img/general/icon_arrow.svg);
      background-size: contain;
      background-repeat: no-repeat;

      &:focus {
        outline: none;
      }
    }

    &-next {
      transform: translateY(-50%) rotate(180deg);
      right: 20px;
    }

    &-prev {
      left: 20px;
    }

    &-dots {
      display: flex;
      justify-content: center;
      list-style-type: none;
      margin-top: 65px;

      li {

        &:not(:first-child) {
          margin-left: 10px;
        }

        &:hover,
        &.slick-active {

          button {
            background-color: $orange;
          }
        }
      }

      button {
        border: none;
        background: none;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        font-size: 0;
        background-color: $white;
        outline: none;
        transition: $basicTransition;
      }
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(0.9);
  }
    100% {
    transform: scale(1);
  }
}

