@import "variables";
@import "mixins";

.expedition {
  margin-top: 11.57vh;

  &-page {
    min-height: 100vh;
    padding-bottom: 40px;
    box-sizing: border-box;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/content/expedition/bg_teaser_001.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    @include respond-to(small_tablet) {
      padding-bottom: 20px;
    }

    .teaser {
      display: flex;
      align-items: center;
      margin-top: 16vh;

      @include respond-to(tablet) {
        margin-top: 10vh;
      }

      @include respond-to(small_tablet) {
        margin-top: 40px;
      }

      &__desc {
        margin-top: 4.5vh;

        @include respond-to(small_tablet) {
          margin-top: 30px;
        }
      }
    }

    .title {
      line-height: 120%;

      @include respond-to(phone) {
        font-size: 4.8rem;
      }

      br {

        @include respond-to(tablet) {
          display: none;
        }
      }
    }

    .btn {
      min-width: 190px;
      transition: $basicTransition;

      @include respond-to(phone) {
        min-width: initial;
        width: 100%;
      }

      &-wrap {
        margin-top: 4.5vh;

        @include respond-to(small_tablet) {
          margin-top: 25px;
        }

        @include respond-to(phone) {
          width: 100%;
        }
      }
    }

    &--places {
      padding-top: 145px;
      background-image: none;

      @include respond-to(small_tablet) {
        padding-top: 100px;
      }

      &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100vh;
        background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../img/content/expedition/bg_teaser_003.jpg);
        background-position: center;
        background-size: cover;
      }

      .header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        width: 100%;

        @include respond-to(small_tablet) {
          display: flex;
          height: 70px;
        }

        &__item {

          &:first-child {
            transition: all 0.3s;

            &.is-hide {

              @include respond-to(phone) {
                visibility: hidden;
                opacity: 0;
              }
            }
          }
        }
      }

      .container {
        width: 1080px;

        @include respond-to(desktop) {
          width: 1100px;
        }
      }
    }
  }
}

.places {
  position: relative;

  .text {
    text-align: left;
  }

  &__section {

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  &__title {
    font-family: $oswald;
    font-size: 3.6rem;
    font-weight: normal;
    line-height: 150%;

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }

    & + p {
      margin-top: 40px;

      @include respond-to(small_tablet) {
        margin-top: 30px;
      }
    }
  }

  &__incut {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    padding: 15px 0;
    border-top: 1px solid #008ddb;
    border-bottom: 1px solid #008ddb;

    @include respond-to(small_tablet) {
      flex-direction: column;
      margin-top: 30px;
    }

    .places__title {
      margin-left: 30px;

      @include respond-to(small_tablet) {
        font-size: 3.6rem;
        margin-left: 0;
      }
    }

    &-item {

      @include respond-to(phone) {
        width: 100%;
      }

      &:not(:first-child) {
        margin-left: 40px;

        @include respond-to(small_tablet) {
          margin-top: 20px;
          margin-left: 0;
        }

        @include respond-to(fablet) {
          margin-top: 10px;
          margin-left: 0;
        }
      }

      &:first-child {
        display: flex;
        align-items: center;

        @include respond-to(fablet) {
          flex-direction: column;
        }
      }
    }

    &-img {
      flex-shrink: 0;
      width: 90px;
      height: 90px;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  &__img {
    margin-top: 40px;
    width: 100%;

    figcaption {
      margin-top: 20px;
      font-size: 1.6rem;
      font-style: italic;
      line-height: 150%;
      text-align: center;
    }
  }

  &__quote {
    margin-top: 40px;
  }

  &__sign {
    margin-top: 40px;
    font-style: italic;
  }

  &__footer {
    position: relative;
    margin-top: 40px;
    padding-top: 40px;
    font-style: italic;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 75%;
      max-width: 530px;
      height: 1px;
      background-color: $white;
    }
  }
}

.photo-tooltip {
  display: inline-flex;
  align-items: baseline;
  padding-right: 4px;
  cursor: pointer;

  &:hover {

    .photo-tooltip__title {
      border-bottom-color: transparent;
      background-color: $orange;
    }

    .photo-tooltip__img {
      display: block;

      @include respond-to(laptop) {
        display: none;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 12px;
    margin-left: 2px;
    background-image: url(../img/general/icon_camera.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--down {

    .photo-tooltip{
      
      &__img {
        top: calc(100% + 34px);
        bottom: auto;

        &::before {
          top: auto;
          bottom: calc(100% + 2px);
          border-top-color: transparent;
          border-bottom-color: #fff;
        }
      }
    }
  }

  &--small {

    .photo-tooltip {

      &__img {
        width: 318px;
        height: 214px;
        padding: 25px 0;
        background-color: $white;

        @include respond-to(desktop) {
          width: 240px;
          height: 162px;
        }
      }
    }
  }

  &__title {
    position: relative;
    border-bottom: 3px solid #f3410e;
    transition: all 0.3s;
  }

  &__img {
    position: absolute;
    bottom: calc(100% + 30px);
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    display: none;
    width: 458px;
    height: 308px;
    border: 3px solid $white;
    border-radius: 3px;
    box-sizing: border-box;
    // overflow: hidden;

    @include respond-to(desktop) {
      width: 358px;
      height: 240px;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border: 30px solid transparent;
      border-top-color: $white;
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.8);
  overflow: auto;

  &-photo {
    display: none;

    &.is-show {
      display: flex !important;
    }

    &__img {
      width: 458px;
      height: 308px;
      border: 3px solid #fff;
      border-radius: 3px;
      box-sizing: border-box;

      @include respond-to(phone) {
        width: 276px;
        height: 184px;
      }
    }
  }

  &-expedition-nav {
    position: fixed;
    top: calc(-100% - 200px);
    display: flex !important;
    padding-top: 110px;
    box-sizing: border-box;
    background-color: #000;
    transition: all 0.3s;

    @include respond-to(tablet) {
      align-items: flex-start;
    }

    &.is-show {
      top: 0;
    }

    .btn-close {
      top: 30px;
      right: 30px;
      width: 33px;
      height: 33px;
    }
  }

  &__content {
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

.expedition-nav {
  max-height: 80vh;
  font-family: $oswald;
  text-transform: uppercase;
  overflow: auto;

  &__title {
    padding-bottom: 10px;
    border-bottom: 3px solid $orange;
    font-size: 5.4rem;
    font-weight: bold;
    line-height: 130%;

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }
  }

  &__list {
    margin-top: 50px;

    @include respond-to(small_tablet) {
      margin-top: 40px;
    }
  }

  &__item {
    padding: 10px 0;
    font-size: 2.4rem;
    line-height: 130%;
    cursor: pointer;
    transition: $basicTransition;

    @include respond-to(small_tablet) {
      font-size: 2rem;
    }

    &:hover {
      color: $orange;
    }
  }
}

.btn-close {
  position: absolute;
  top: 14px;
  right: 34px;
  z-index: 10;
  width: 20px;
  height: 20px;
  background-image: url(../img/general/icon_cross.svg);
  background-size: cover;
  background-repeat: no-repeat;

  &--black {
    background-image: url(../img/general/icon_cross_black.svg);
  }
}

.quote {
  padding: 30px 0;
  border-top: 1px solid #008ddb;
  border-bottom: 1px solid #008ddb;

  &::before {
    content: '';
    transform: scale(-1, 1);
    display: block;
    width: 76px;
    height: 60px;
    margin-bottom: 20px;
    background-image: url(../img/general/icon_quote.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @include respond-to(fablet) {
      width: 66px;
      height: 52px;
    }
  }

  &__text {
    font-size: 2.1rem;
    font-style: italic;
    line-height: 150%;

    @include respond-to(tablet) {
      font-size: 1.8rem;
    }

    @include respond-to(fablet) {
      font-size: 1.6rem;
    }
  }

  &__author {
    margin-top: 20px;
    font-size: 1.6rem;
    line-height: 130%;

    @include respond-to(fablet) {
      font-size: 1.4rem;
    }
  }
}
