@import "variables";
@import "mixins";

.header {
  display: flex;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;

  @include respond-to(laptop) {
    padding: 20px;
  }

  &--section {
    justify-content: flex-end;
    height: 90px;

    @include respond-to(desktop) {
      height: 70px;
    
    }
    @include respond-to(laptop) {
      height: 90px;
    }

    @include respond-to(small_tablet) {
      display: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-left: auto;
    }
  }

  .logo {

    &:not(:first-child) {
      margin-left: 3.125vw;

      @include respond-to(laptop) {
        margin-left: 30px;
      }

      @include respond-to(small_tablet) {
        margin-left: 10px;
      }
    }
  }
}
