@import "variables";
@import "mixins";

html {
  height: 100%;
  min-height: 100%;
  font-size: $basic-font-size;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
ol,
ul,
input,
button,
textarea {
  padding: 0;
  margin: 0;
}

body {
  max-width: 2560px;
  margin: 0 auto;
  height: 100%;
  font-family: $rubik, 'Helvetica', sans-serif;
  color: $white;
  background-color: rgba(0, 0, 0, 0.6);
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  &.is-inactive {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

img {
  display: block;
  width: 100%;
  height: auto;

  &.cover {
    height: 100%;
    object-fit: cover;
  }

  &.contain {
    height: 100%;
    object-fit: contain;
  }
}

button,
input {
  border: none;
  background: none;
  outline: none;
}

.title {
  font-family: $oswald;
  font-size: 7.2rem;
  font-weight: bold;
  line-height: 130%;
  text-transform: uppercase;
  transition: $basicTransition;

  @include respond-to(laptop) {
    font-size: 4.8rem;
  }

  @include respond-to(small_tablet) {
    font-size: 3.6rem;
  }

  @include respond-to(small_phone) {
    font-size: 2.9rem;
  }

  span {
    display: block;
    font-family: $rubik;
    font-size: 2.1rem;
    font-weight: 300;
    font-style: italic;
    line-height: 1;
    text-transform: initial;

    @include respond-to(laptop) {
      font-size: 1.8rem;
    }

    @include respond-to(small_tablet) {
      margin-top: 0;
    }
  }

  &--medium {
    font-size: 5.208vw;

    @include respond-to(tablet) {
      font-size: 8.36vh;
    }

    @include respond-to(small_tablet) {
      font-size: 4.8rem;
    }

    @include respond-to(phone) {
      font-size: 3.8rem;
    }

    span {

      @include respond-to(tablet) {
        font-size: 2.05vh;
      }

      @include respond-to(small_tablet) {
        font-size: 2.1rem;
      }
    }
  }

  &--big {
    font-size: 6.692vw;

    @include respond-to(desktop) {
      font-size: 5.208vw;
    }

    @include respond-to(tablet) {
      font-size: 7.03125vh;
    }

    @include respond-to(small_tablet) {
      font-size: 4.8rem;
    }

    @include respond-to(small_phone) {
      font-size: 4.2rem;
    }

    span {
      margin-bottom: -20px;
      font-size: 3.750vw;

      @include respond-to(desktop) {
        margin-bottom: -5px;
        font-size: 3.333vw;
      }

      @include respond-to(tablet) {
        margin-bottom: 0;
        font-size: 4.6875vh;
      }

      @include respond-to(small_tablet) {
        font-size: 2.4rem;
      }
    }
  }

  &--small {
    font-size: 4.8rem;
    line-height: 130%;

    @include respond-to(laptop) {
      font-size: 3.6rem;
    }
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }

  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to(small_tablet) {
      flex-direction: column;
    }

    span {
      display: block;
      margin-right: 20px;
      font-family: $rubik;
      font-size: 1.875vw;
      font-weight: 300;
      font-style: italic;
      line-height: 130%;
      text-align: right;

      @include respond-to(tablet) {
        font-size: 3.51vh;
      }

      @include respond-to(small_tablet) {
        margin-right: 0;
        margin-bottom: 5px;
        font-size: 1.4rem;
        text-align: center;
      }
    }
  }
}

.natoons__item br {
  display: none;

  @include respond-to(small_tablet) {
    display: block;
  }
}

.subtitle {
  margin-top: 20px;
  font-size: 1.6rem;
  line-height: 130%;
  text-transform: uppercase;

  @include respond-to(laptop) {
    margin-top: 10px;
  }

  @include respond-to(small_tablet) {
    margin-top: 17px;
  }

  @include respond-to(small_phone) {
    font-size: 1.4rem;
  }

  &--orange {
    color: $orange;
  }

  &--big {
    font-family: $oswald;
    font-size: 1.875vw;
    text-align: center;
    text-transform: initial;

    @include respond-to(tablet) {
      font-size: 3.6rem;
    }

    @include respond-to(small_tablet) {
      font-size: 2.4rem;
    }
  }

  &--center {
    text-align: center;
  }

  &--read {
    display: none;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 26px;
      height: 22px;
      margin-right: 10px;
      background-image: url(../img/general/icon_book_orange.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &--lock {
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 18px;
      height: 25px;
      margin-top: -10px;
      margin-right: 10px;
      background-image: url(../img/general/icon_lock.svg);
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.text {
  font-size: 1.8rem;
  line-height: 150%;
  text-align: center;

  @include respond-to(small_tablet) {
    font-size: 1.6rem;
  }

  @include respond-to(small_phone) {
    font-size: 1.4rem;
  }

  &:not(:first-of-type) {
    margin-top: 30px;
  }

  &--small {
    font-size: 1.4rem;
    line-height: 120%;
  }

  a {
    color: $white;
    border-bottom: 3px solid $orange;
    transition: $basicTransition;

    &:hover {
      border-bottom-color: transparent;
      background-color: $orange;
    }
  }
}

.logo {
  display: block;

  &--wwf {
    width: 90px;
    height: 90px;

    @include respond-to(desktop) {
      width: 70px;
      height: 70px;
    }

    @include respond-to(laptop) {
      width: 50px;
      height: 50px;
    }

    @include respond-to(small_tablet) {
      width: 30px;
      height: 30px;
    }
  }

  &--kinder {
    width: 194px;
    height: 60px;

    @include respond-to(desktop) {
      width: 174px;
      height: 53px;
    }

    @include respond-to(laptop) {
      width: 108px;
      height: 33px;
    }

    @include respond-to(small_tablet) {
      width: 65px;
      height: 20px;
    }
  }

  &--natoons {
    width: 293px;
    height: 140px;

    @include respond-to(small_tablet) {
      width: 217px;
      height: 100px;
    }
  }

  &-list {
    display: flex;
    align-items: center;

    .logo {
    
      &:not(:first-child) {
        margin-left: 3.125vw;

        @include respond-to(small_tablet) {
          margin-left: 50px;
        }
      }
    }
  }
}

.scroll-down {
  align-self: center;
  margin-top: auto;
  padding-top: 5.555vh;
  cursor: pointer;

  @include respond-to(small_tablet) {
    margin-top: 30px;
    padding-top: 0;
  }

  &:hover {

    .wheel {
      fill: $orange;
      transition: $basicTransition;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    animation: spin 1.3s infinite linear;

    svg {

      @include respond-to(small_tablet) {
        width: 17.69px;
        height: 40px;
      }
    }
  }
}

.container {
  width: 67.708vw;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  @include respond-to(laptop) {
    width: 100%;
    padding: 0 50px;
  }

  @include respond-to(small_tablet) {
    padding: 0 20px;
  }

  .inner {
    width: 100%;
    padding: 0 5.729vw;
    box-sizing: border-box;

    @include respond-to(laptop) {
      padding: 0;
    }
  }
}

.fp-tableCell {
  display: block;
}

#fp-nav {
  z-index: 90;

  &.fp-right {
    right: 25px;

    @include respond-to(laptop) {
      right: 15px;
    }
  }

  ul {

    li {
      width: auto;
      height: auto;
      margin: 0;

      &:not(:first-child) {
        margin-top: 7px;
      }

      a {
        position: relative;
        width: 10px;
        height: 10px;
        margin: 0 auto;
        border-radius: 100%;
        background-color: $white;

        &:hover,
        &.active {
          background-color: $orange;

          &::before {
            display: block;
          }

          span {

            &:nth-child(2) {
              transform: translate(-50%, -50%);
              display: block;
              width: 4px;
              height: 4px;
              margin: 0;
              border-radius: 100%;
              background-color: $white;
              overflow: hidden;
            }
          }
        }

        &::before {
          content: '';
          @include positionCenter();
          display: none;
          width: 14px;
          height: 14px;
          border-radius: 100%;
          background-color: $orange;
        }

        span {
          display: none;
        }
      }
    }
  }
}

@keyframes spin {
  0% {
    transform: translate3d(0, -10px, 0);
  }
  50% {
    transform: translate3d(0, 10px, 0);
  }
  100% {
    transform: translate3d(0, -10px, 0);
  }
}
