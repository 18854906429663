@import "variables";
@import "mixins";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  box-sizing: border-box;
  background-color: #222;

  @include respond-to(laptop) {
    padding: 17px 20px;
  }

  &__item {

    &:not(:first-child) {
      margin-left: 40px;
    }
  }

  .logo {
    
    &:not(:first-child) {
      margin-left: 50px;
    }

    &--wwf {
      width: 60px;
      height: 60px;

      @include respond-to(laptop) {
        width: 50px;
        height: 50px;
      }
    }

    &--kinder {
      width: 162px;
      height: 50px;

      @include respond-to(laptop) {
        width: 104px;
        height: 32px;
      }
    }
  }

  .text {

    @include respond-to(laptop) {
      font-size: 1.4rem;
    }
  }
}
